import Axios from 'axios';
import store from 'store';
import storage from 'services/utils/storage';

import responseStatus from 'services/utils/responseStatus';

import config from 'config';
// import { useDispatch } from 'react-redux';
import {
  startLoading,
  endLoading,
  showLoading
} from 'store/slices/loading/loadingSlice';
import { toast } from 'react-toastify';
import { toastMsg } from 'services/utils/variables';
import {
  checkAuthToken,
  getCookie,
  ToastTemplate
} from 'services/utils/generic.methods';
import { globalLogout } from 'services/utils/generic-logout-method';
import { RESETPASSWORD } from './endpoints';

// const dispatch = useDispatch();
// const language = useSelector(selectLangauge);
/**
 * @name @ApiService
 * @description Default Api Service for Auth and Defaul Queries
 * */

/* General ApiServices requests and configurations */

/** Creating Axios instance */
const instance = Axios.create({
  baseURL: config.apiUrl + config.apiVersion
});
/** Creating Axios instance */
const WCFInstance = Axios.create({
  baseURL: config.apiWCFUrl
});

/** Creating Axios instance */
const reportInstance = Axios.create({
  baseURL: config.reportUrl
});

const ApiServices = {
  instance,
  WCFInstance,
  reportInstance,
  /**
   * @name @init
   * @description init axios interceptor on entry point and set/remove auth on app load
   **/
  init() {
    instance.interceptors.request.use(config => {
      if (!config.url.includes('quick-stats') && !config.url.includes('quickstats')) {
        store.dispatch(showLoading(true));
        store.dispatch(startLoading());
      }
      if (
        !checkAuthToken() &&
        // !(
        //   allowedUnAuthUrls(config.data).forEach(element => {
        //     if (config.url.indexOf(element) === -1) {
        //       return true;
        //     }
        //   }) ||
        // allowedUnAuthUrls(config.data).includes(config.url)) ||
        !(
          allowedUnAuthUrls(config.data).some(_url =>
            config.url.includes(_url)
          ) || tokenVerifyUrl(config.url)
        )
      ) {
        toast.error(toastMsg.errors.inValidAuthToken);
        globalLogout(true);
        return false;
      }
      return config;
    });

    instance.interceptors.response.use(
      function (response) {
        // destroy Loader
        destroyLoader();
        // Do something with response data
        return response;
      },
      function (error) {
        if (error?.response?.status === responseStatus.UNAUTHORIZED) {
          storage.deleteItem('Auth');
          storage.deleteItem('Profile');
        }
        // Do something with response error
        destroyLoader();
        return Promise.reject(error);
      }
    );

    // WCF instance for Legacy Portal APIs
    WCFInstance.interceptors.request.use(config => {
      if (!config.url.includes('getOperatorsForTransfer')) {
        store.dispatch(showLoading(true));
        store.dispatch(startLoading());
      }
      if (
        !checkAuthToken() &&
        !allowedUnAuthUrls(config.data || {}).includes(config.url)
      ) {
        toast.error(toastMsg.errors.inValidAuthToken);
        globalLogout(true);
        return false;
      }
      return config;
    });

    WCFInstance.interceptors.response.use(
      function (response) {
        // destroy Loader
        destroyLoader();
        // Do something with response data
        return response;
      },
      function (error) {
        if (error?.response?.status === responseStatus.UNAUTHORIZED) {
          storage.deleteItem('Auth');
          storage.deleteItem('Profile');
        }
        // Do something with response error
        destroyLoader();
        return Promise.reject(error);
      }
    );

    // Report instance for Legacy Portal APIs
    reportInstance.interceptors.request.use(config => {
      store.dispatch(showLoading(true));
      store.dispatch(startLoading());
      if (
        !checkAuthToken() &&
        !allowedUnAuthUrls(config.data || {}).includes(config.url)
      ) {
        toast.error(toastMsg.errors.inValidAuthToken);
        globalLogout(true);
        return false;
      }
      return config;
    });

    reportInstance.interceptors.response.use(
      function (response) {
        // destroy Loader
        destroyLoader();
        // Do something with response data
        return response;
      },
      function (error) {
        if (error?.response?.status === responseStatus.UNAUTHORIZED) {
          storage.deleteItem('Auth');
          storage.deleteItem('Profile');
        }
        // Do something with response error
        destroyLoader();
        return Promise.reject(error);
      }
    );

    /* Setting Auth Header*/
  },

  /* General query for get request */
  query(resource, params) {
    return instance.get(resource, params).catch(error => {
      throw new Error(`ApexChat Generic ApiServices ${error}`);
    });
  },

  /* General get request */
  get(resource, headers) {
    this.setAuthHeader();
    return instance.get(resource, {
      headers
    });
  },

  /* Get request with params*/
  getWithParams(resource, params) {
    this.setAuthHeader();
    return instance.get(resource, params).catch(error => {
      if (error.message === 'Network Error')
        return toast.error(toastMsg.errors.networkError);
      return error?.response?.data?.error;
    });
  },

  /* General post request */
  post(resource, params) {
    this.setAuthHeader();
    return instance.post(resource, params);
  },

  /* General post request */
  postAttachements(resource, params) {
    this.setAuthHeader();
    this.setMultipartHeader();
    return instance.post(resource, params).catch(error => {
      if (error.message === 'Network Error')
        return toast.error(toastMsg.errors.networkError);
      return error?.response?.data;
    });
  },

  /* General put request */
  put(resource, params) {
    this.setAuthHeader();
    return instance.put(resource, params).catch(error => {
      if (error.message === 'Network Error')
        return toast.error(toastMsg.errors.networkError);
      return error?.response?.data;
    });
  },

  /* General delete request */
  delete(resource, params) {
    return instance.delete(`${resource}/${params}`).catch(error => {
      throw new Error(`ApexChat Delete ApiServices ${error}`);
    });
  },
  setAuthHeader() {
    const auth = getCookie('Auth');
    if (auth) {
      instance.defaults.headers.common['Authorization'] = `Bearer ${auth}`;
      instance.defaults.headers['Content-Type'] = `application/json`;
    } else {
      instance.defaults.headers.common['Authorization'] = `Bearer`;
    }
  },

  setMultipartHeader() {
    instance.defaults.headers['Content-Type'] = `multipart/form-data`;
  },

  /* WCF General get request */
  WCF_get(resource, headers) {
    this.setWCFAuthHeader();
    return WCFInstance.get(resource, {
      headers
    });
  },

  WCF_getWithParams(resource, params) {
    this.setWCFAuthHeader();
    return WCFInstance.get(resource, params).catch(error => {
      if (error.message === 'Network Error')
        return toast.error(toastMsg.errors.networkError);
      return error?.response?.data?.error;
    });
  },

  /* WCF General get request */
  WCF_post(resource, params) {
    this.setWCFAuthHeader();
    return WCFInstance.post(resource, params).catch(error => {
      return error;
    });
  },

  setWCFAuthHeader() {
    const auth = getCookie('Auth');
    if (auth) {
      WCFInstance.defaults.headers.common['Authorization'] = `Bearer ${auth}`;
    } else {
      WCFInstance.defaults.headers.common['Authorization'] = `Bearer`;
    }
  },
  /* Report General get request with params */
  report_getwithParams(resource, params) {
    this.setReportAuthHeader();
    return reportInstance.get(resource, params).catch(error => {
      if (error.message === 'Network Error')
        return toast.error(toastMsg.errors.networkError);
      throw new Error(`ApexChat Get ApiServices ${error}`);
    });
  },

  /* Report General get request */
  report_get(resource, options) {
    this.setReportAuthHeader();
    return reportInstance.get(resource, options);
  },

  /* Report General post request */
  report_post(resource, params) {
    this.setReportAuthHeader();
    return reportInstance.post(resource, params).catch(error => {
      throw new Error(`Post ApiServices ${error}`);
    });
  },

  setReportAuthHeader() {
    const auth = getCookie('Auth');
    if (auth) {
      reportInstance.defaults.headers.common[
        'Authorization'
      ] = `Bearer ${auth}`;
    } else {
      reportInstance.defaults.headers.common['Authorization'] = `Bearer`;
    }
  },

  dataWrapper(data, status) {
    // data wrapper will be removed if WebApi project will send data in ideal way. with status code/data/message etc..

    switch (status) {
      case 'success':
        return {
          status: data.status || status,
          code: data.code || 200,
          data: data,
          message: data.message || 'success'
        };
      case 'error':
        return {
          status: data.status || status,
          code: data.code || 400,
          data: data,
          message: data.message || 'error'
        };
      default:
        return {
          status: 'error',
          code: 400,
          data: null,
          message: 'error'
        };
    }
  }
};

// destroy Loader: Complete loading to full and set show loading false
const destroyLoader = () => {
  store.dispatch(endLoading());
  setTimeout(() => {
    store.dispatch(showLoading(false));
  }, 500);
};

export default ApiServices;

export const allowedUnAuthUrls = (data = {}) => [
  '/login',
  '/host/meta',
  RESETPASSWORD(data)
];

const tokenVerifyUrl = url => {
  let tempURL = url.split('?');
  if (tempURL[0] === '/auth/verifyToken') {
    return true;
  } else {
    return false;
  }
};
