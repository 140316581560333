import { combineReducers } from '@reduxjs/toolkit';
import userPreferenceSettingsReducer from 'store/slices/userPreferenceSettings/userPreferenceSlice';
import counterReducer from 'features/counter/counterSlice';
import authReducer from 'store/slices/auth/authSlice';
import generalReducer from 'store/slices/general/generalSlice';
import companyReducer from 'store/slices/company/companySlice';
import companiesReducer from 'store/slices/companies/companiesSlice';
import chatsReducer from 'store/slices/chat/chatSlice';
import leadsReducer from 'store/slices/lead/leadSlice';
import userReducer from 'store/slices/users/userSlice';
import reportsReducer from 'store/slices/report/reportsSlice';
import fetchGeneralData from 'store/slices/fetchGeneralData/fetchGeneralDataOnAuth';
import loadingReducer from 'store/slices/loading/loadingSlice';
import provisionReducer from 'store/slices/provisioning/ProvisioningSlice';
import rolesReducer from 'store/slices/roles/rolesSlice';
import scriptLoadReducer from 'store/slices/script-load/scriptLoadSlice';
import filtersReducer from 'store/slices/analytics/analyticSlice';
import navbarReducer from 'store/slices/sidebar-nav/sidebarNavSlice';
import MessagingChatsReducer from 'store/slices/messagingChats/messagingChats';
import SuggestionsReducer from 'store/slices/messagingChats/suggestions';
import hostsReducer from 'store/slices/hosts/hostSlice';
import widgetEditorReducer from 'store/slices/widget-editor/widgetEditorSlice';
import profileReducer from 'store/slices/profile/profileSlice';
import widgetAppearanceReducer from 'store/slices/widget-editor/appearanceSlice';
import voicesSlice from 'store/slices/voiceDashboard/voice';

const appReducers = combineReducers({
  userPreferenceSettings: userPreferenceSettingsReducer,
  counter: counterReducer,
  auth: authReducer,
  general: generalReducer,
  company: companyReducer,
  fetchGeneralData: fetchGeneralData,
  loading: loadingReducer,
  companies: companiesReducer,
  chats: chatsReducer,
  leads: leadsReducer,
  user: userReducer,
  reports: reportsReducer,
  provisions: provisionReducer,
  roles: rolesReducer,
  scriptLoad: scriptLoadReducer,
  MessagingChats: MessagingChatsReducer,
  Suggestions: SuggestionsReducer,
  filters: filtersReducer,
  navbar: navbarReducer,
  hosts: hostsReducer,
  widgetEditor: widgetEditorReducer,
  themeProfile: profileReducer,
  widgetAppearance: widgetAppearanceReducer,
  voicesDashboard: voicesSlice
});

const rootReducer = (state, action) => {
  if (action.type === 'auth/logout') {
    return appReducers(undefined, action);
  }
  return appReducers(state, action);
};

export default rootReducer;
